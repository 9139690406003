@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf9f6;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.josefin-sans-heading {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 650;
  font-style: bold;
}

.josefin-sans-body {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
}

p , h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Sans", sans-serif;
}

.josefin-sans{
  font-family: "Josefin Sans", sans-serif;
  
}